.topology-modal {
    height: 90%;
}

.topology-modal .node {
}

.topology-modal .metric {
    font-weight: normal;
}

.topology-modal .inflight-color {
    color: white;
    background-color: var(--pf-v5-global--primary-color--100);
}

.topology-modal .failed-color {
    color: white;
    background-color: var(--pf-v5-global--danger-color--100);
}
.topology-modal .total-color {
    color: white;
    background-color: var(--pf-v5-global--Color--200);
}

.topology-modal .metric-inflight {
    position: absolute;
    left: 86px
}

.topology-modal .metric-failed {
    position: absolute;
    right: 86px
}

.topology-modal .metric-panel-total {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 62px;
}

.topology-modal .metric-total {

}

.topology-modal .topology-panel .common-node .icon {
    height: 32px;
    width: 32px;
}