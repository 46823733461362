.tag-row .tag-row-first {
    border-radius: 3px;
    border-color: #909090;
    border-width: 1px;
    border-style: solid;
    /*color: #fff;*/
    /*font-family: sans-serif;*/
    /*font-size: 14px;*/
    /*font-weight: 700;*/
    min-width: 80px;
    padding: 6px 0;
    text-align: center;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .1);
}

#Paths .pf-v5-c-tree-view__list .pf-v5-c-tree-view__node-text {
   width: 100%;
}

.path-row .path-row-path {
    color: initial;
}

.path-row .path-row-path-param {
    color: #ec7a08;
}

.oper-row .oper-name {
    border-radius: 3px;
    color: #fff;
    min-width: 70px;
    padding: 5px 0;
    text-align: center;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .1);
}

.oper-row .oper-get {
    background: #61affe;
}

.oper-row .oper-post {
    background: #49cc90;
}

.oper-row .oper-delete {
    background: #f93e3e;
}

.oper-row .oper-put {
    background: #bdb2ff;
}

.oper-row .oper-options {
    background: #0d5aa7;
}

.oper-row .oper-head {
    background: #9012fe;
}

.oper-row .oper-patch {
    background: #50e3c2;
}

.oper-row .no-oper {
    background: gray;
    opacity: 0.2;
}

.wmde-markdown {
    background-color: transparent;
}





