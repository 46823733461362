.karavan .jwt-page {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.karavan .jwt-page .scrolled-section {
    overflow: auto;
    flex-shrink: unset;
    flex-grow: 1;
    background-color: var(--pf-v5-global--BackgroundColor--light-300);
}

.karavan .jwt-page .inventory-card .result-button {
    padding: 0;
}
.karavan .jwt-page .projects-card .badge {
    font-size: 14px;
    font-weight: 400;
    padding: 4px 8px 4px 8px;
    min-width: 70px;
}

.karavan .jwt-page .projects-card .labeled-button {
    padding: 0 5px 0 5px;
}

.karavan .jwt-page .icon {
    height: 16px;
    width: 16px;
}

.karavan .jwt-page .services-table {
    padding-right: 10px;
}

.karavan .jwt-page .container-button,
.karavan .jwt-page .api-button {
    padding: 0;
}

.karavan .jwt-page .switch-rounded {
    border: solid 1px var(--pf-v5-global--BorderColor--100);
}

.karavan .projects-card .clabel {
    width: 100%;
    justify-content: center;
}
.karavan .projects-card .slabel {
    width: 100%;
    justify-content: start;
}
.karavan .projects-card .elabel {
    width: 100%;
    justify-content: end;
}

.karavan .projects-card .toggle-button {
    padding-left: 0;
    padding-right: 0;
}