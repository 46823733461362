.karavan .login-page .login {
    width: 470px;
}

.karavan .login-page .logo {
    /*height: 48px;*/
}

.karavan .login-page .title {
    color: var(--pf-v5-global--link--Color);
    line-height: 36px;
    font-size: 20px;
    font-weight: 800;
}
