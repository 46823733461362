.catalog-modal {
    height: 80%;
    color: var(--pf-v5-global--Color--100);
}

.catalog-modal .catalog-card {
    cursor: pointer;
}

.catalog-modal .catalog-card .pf-v5-c-card__header-main {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: hidden;
}

.catalog-modal .catalog-card .header-labels .pf-v5-c-card__header-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.catalog-modal .catalog-card .icon {
    height: 20px;
    width: auto;
    border: none;
    margin-right: 6px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.catalog-modal .pf-v5-c-form.pf-v5-m-horizontal .pf-v5-c-form__group {
    display: contents;
}

.catalog-modal .pf-v5-c-card__body {
    padding-bottom: 0;
    height: 54px;
}


.catalog-modal .catalog-card p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: var(--pf-v5-global--Color--200);
}

.catalog-modal .catalog-card .catalog-logo {
    width: 24px;
    height: 24px;
}

.catalog-modal .pf-v5-c-card__footer {
    padding-bottom: 1em;
}

.catalog-modal .footer {
    display: flex;
    flex-direction: row;
    margin-top: 6px;
    flex-wrap: wrap;
}

.catalog-modal .catalog-card .catalog-card-title {
    color: var(--pf-v5-global--Color--100);
    font-weight: bold;
    margin-left: 6px;
}

.catalog-modal .catalog-card .header-labels {
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.catalog-modal .catalog-card .header-name {
    margin-top: 5px;
}

.catalog-modal .catalog-card .footer-labels {
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.catalog-modal .version,
.catalog-modal .support-type,
.catalog-modal .support-level {
    white-space: nowrap;
}

.catalog-modal .labels {
    /*opacity: 0.5;*/
    font-weight: 200;
    text-wrap: none;
}

.catalog-modal .catalog-card:hover .pf-v5-c-badge {
    margin-right: 1px;
}

.catalog-modal .catalog-card:hover {
    background-color: var(--pf-v5-global--palette--black-200);
}

.catalog-modal .catalog-card:hover .pf-v5-c-badge.pf-m-read {
    --pf-v5-c-badge--BackgroundColor: var(--pf-v5-global--palette--black-150);
}

.catalog-modal .catalog-card .talisman-color {
    background-color: #E97826;
}