.viewer-group .dev-action-button-place {
    display: none;
}

.viewer-group .dev-action-button {
    cursor: not-allowed !important;
    pointer-events: none !important;
    visibility: hidden !important;
}

.karavan .nav-buttons {
    background: var(--pf-v5-c-page__header--BackgroundColor);
    min-width: 64px;
}

.karavan .nav-buttons .pf-v5-c-button svg {
    fill: var(--pf-v5-global--Color--light-100);
}

.karavan .nav-buttons .environment {
    margin-bottom: 16px;
}
.karavan .nav-buttons .environment .pf-v5-c-label {
    background-color: transparent;
}

.karavan .nav-buttons .environment .pf-v5-c-label .pf-v5-c-label__text {
    color: white;
}

.karavan .nav-buttons .logo {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 32px;
    height: 32px;
}

.karavan .nav-buttons .pf-v5-c-button {
    padding: 0;
    width: 64px;
    height: 64px;
    color: var(--pf-v5-global--Color--light-100);
}

.karavan .nav-buttons .pf-v5-c-button svg {
    width: 32px;
}

.karavan .nav-buttons .avatar {
    width: 32px;
    height: 32px;
    border: solid var(--pf-v5-global--Color--light-100) 1px;
    border-radius: 32px;
    padding: 8px;
}

.karavan .nav-buttons .pf-v5-c-button.pf-m-plain {
    border-left-width: 3px;
    border-left-style: solid;
    border-left-color: transparent;
    border-radius: 0;
}

.karavan .nav-button-selected .pf-v5-c-button.pf-m-plain {
    border-left-color: var(--pf-v5-global--active-color--400);
    background-color: var(--pf-v5-global--BackgroundColor--dark-400);
}