.karavan .data-editor {
    height: 100%;
}

.karavan .data-editor  .main-tabs {
    background-color: var(--pf-v5-c-page__main-section--m-light--BackgroundColor);
}

.karavan .data-editor:before {
    border: solid;
    border-bottom-color: var(--pf-v5-global--BorderColor--100);
    border-width: 0 0 var(--pf-v5-global--BorderWidth--sm) 0;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
}

.karavan .data-editor  .main-tabs .top-menu-item {
    display: flex;
    flex-direction: row;
}

.karavan .data-editor  .main-tabs .top-menu-item .count {
    background: var(--pf-v5-global--active-color--100);
    color: white;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    min-width: 0;
}

.karavan .data-editor  .main-tabs .pf-v5-c-tabs__link .pf-v5-c-tabs__item-icon {
    height: 24px;
    margin-right: 0;
}

.karavan .data-editor .main-tabs .pf-v5-c-tabs__item-text {
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 6px;
}

